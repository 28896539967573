<template>
    <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-12">
                    <v-toolbar color="primary" dark>
                        <v-spacer />
                        <v-toolbar-title>Reset Password</v-toolbar-title>
                        <v-spacer />
                    </v-toolbar>
                    <v-form
                        v-if="!submitted"
                        ref="form"
                        @submit.prevent="sendEmail">
                        <v-card-text class="pb-0 mt-2">
                            Please enter your email address below and we will send you a link to
                            reset your password.
                            <v-text-field
                                v-model="email"
                                class="mt-5"
                                outlined
                                label="Email"
                                prepend-icon="mdi-account"
                                type="text"
                                :rules="emailRules"
                                required
                                autofocus />
                        </v-card-text>
                        <v-divider />
                        <v-card-actions class="mx-3 py-4">
                            <v-spacer />
                            <v-btn
                                class="px-5 text-none"
                                color="primary"
                                rounded
                                type="submit">
                                Request Reset
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                    <v-form v-if="submitted">
                        <v-card-text class="my-2">
                            Please check your email, we've sent you a link to reset your password.
                        </v-card-text>
                        <v-divider />
                        <v-card-actions class="mx-3 py-4">
                            <v-spacer />
                            <v-btn
                                class="px-5 text-none"
                                color="primary"
                                rounded
                                @click="login">
                                Back
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import { requestPasswordReset } from '@/features/account/services/accountService'; 

export default {
    data() {
        return {
            email: "",
            submitted: false,
            emailRules: [v => !!v || "email is required"],
        };
    },
    methods: {
        async sendEmail() {
            await requestPasswordReset(this.email);
            this.submitted = true;
        },
        login() {
            this.$router.push("/login");
        }
    }
};
</script>
